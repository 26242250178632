import React,{useState} from 'react';
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";


function SearchOverlay({filterBySearch,searchedData,setIsOverlayOpen}) {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const navigate = useNavigate();
  

    const handleSearchSubmit = (event) => {

        if (event.key === "Enter" && searchTerm.trim() !== "") {
          setTimeout(() => {
            navigate("/instructor-led-trainings", {
              state: { searchedData, searchTerm },
            });
            setSearchTerm(""); // Clear the search bar
          }, 100); // Small delay to allow the drawer closing animation
          setIsOverlayOpen(false)

        }
      };
    
      const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        filterBySearch(value); // Call the global filtering function
      };
    
    return (
        <div className='serach_overlay'>
             <div className='overlay_search_input'>
                  <SearchIcon />
                  <InputBase
                    placeholder="Search courses"
                    inputProps={{ "aria-label": "search" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleSearchSubmit}
                  />      
                   <div onClick = {()=>setIsOverlayOpen(false)}>
                <CloseIcon />
                </div>
                </div>
               
        </div>
    );
}

export default SearchOverlay;