import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import logo from "../assests/images/LX_Learning_logo.png";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import "./Header.css";

function Header({
  contactRef,
  filterBySearch,
  searchedData,
  setIsOverlayOpen,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [isMobile, setIsMobile] = useState(false); // State for mobile view
  const [isTab, setIsTab] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480); // Adjust breakpoint as needed
      setIsTab(window.innerWidth <= 833);
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Listen for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener
    };
  }, []);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    console.log("skjjbdkvbnbkjndkjfb");
  };

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterBySearch(value); // Call the global filtering function
  };

  const handleSearchSubmit = (event) => {
    if (event.key === "Enter" && searchTerm.trim() !== "") {
      // Close the drawer synchronously
      setDrawerOpen(false);

      // Navigate after ensuring the drawer is closing
      setTimeout(() => {
        navigate("/instructor-led-trainings", {
          state: { searchedData, searchTerm },
        });
        setSearchTerm(""); // Clear the search bar
      }, 100); // Small delay to allow the drawer closing animation
    }
  };

  const isActive = (path) => location.pathname === path;

  const scrollToGetInTouch = () => {
    if (contactRef?.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <AppBar position="fixed" className="header-appbar">
        <Toolbar className="header-toolbar">
          {isMobile ? (
            <>
              {/* Hamburger Menu Icon */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
                className="hamburger-icon"
              >
                <MenuIcon />
              </IconButton>

              {/* Drawer */}
              <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={handleDrawerClose}
                className="drawer-section"
              >
                <div className="drawer-content">
                  {/* Close Icon */}
                  <div className="drawer-header">
                    <IconButton
                      edge="end"
                      color="inherit"
                      aria-label="close"
                      onClick={handleDrawerClose}
                      className="close-icon"
                    >
                      <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                        X
                      </span>
                    </IconButton>
                  </div>

                  {/* Logo */}
                  <Typography
                    variant="h6"
                    component="div"
                    className="drawer-logo"
                  >
                    <a href="/" style={{ textDecoration: "none" }}>
                      <img
                        src={logo}
                        alt="LX Learning Logo"
                        className="logo-image"
                        loading="lazy"
                      />
                    </a>
                  </Typography>

                  {/* Menu Items */}
                  <div
                    className="drawer-select-items"
                    onClick={() => {
                      handleMenuItemClick("/human-capital-solutions");
                      handleDrawerClose();
                    }}
                  >
                    Human Capital Solutions
                  </div>
                  <div
                    className="drawer-select-items"
                    onClick={() => {
                      handleMenuItemClick("/instructor-led-trainings");
                      handleDrawerClose();
                    }}
                  >
                    Instructor-Led Trainings
                  </div>
                  <div
                    className="drawer-select-items"
                    onClick={() => {
                      handleMenuItemClick("/on-demand-virtual-labs");
                      handleDrawerClose();
                    }}
                  >
                    On-Demand Virtual Labs
                  </div>
                  <div
                    className="drawer-select-items"
                    onClick={() => {
                      handleMenuItemClick("/gen-ai-courses");
                      handleDrawerClose();
                    }}
                  >
                    GenAI Courses
                  </div>
                  <div
                    className="drawer-select-items"
                    onClick={() => {
                      handleMenuItemClick("/leading-life");
                      handleDrawerClose();
                    }}
                  >
                    Leading Life!
                  </div>

                  {/* Search Bar */}
                  <div className="drawer-search">
                    <SearchIcon />
                    <InputBase
                      placeholder="Search courses"
                      inputProps={{ "aria-label": "search" }}
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onKeyDown={handleSearchSubmit}
                      className="search-input"
                    />
                  </div>

                  <Divider />

                  {/* Get in Touch Button */}
                  <Button
                    variant="contained"
                    onClick={() => {
                      scrollToGetInTouch();
                      handleDrawerClose();
                    }}
                    className="get-in-touch-btn"
                  >
                    Get in touch
                  </Button>
                </div>
              </Drawer>

              <Typography variant="h6" component="div" className="header-logo">
                <a href="/" style={{ textDecoration: "none" }}>
                  <img
                    src={logo}
                    alt="LX Learning Logo"
                    className="logo-image"
                    loading="lazy"
                  />
                </a>
              </Typography>
              <div
                className="mobile_searchIcon"
                onClick={() => setIsOverlayOpen(true)}
              >
                <SearchIcon />
              </div>
            </>
          ) : (
            <>
              <Typography variant="h6" component="div" className="header-logo">
                <a href="/" style={{ textDecoration: "none" }}>
                  <img
                    src={logo}
                    alt="LX Learning Logo"
                    className="logo-image"
                    loading="lazy"
                  />
                </a>
              </Typography>
              <div className={`header-nav`}>
                <div
                  className={`nav-section ${isActive("/") ? "active" : ""}`}
                  onClick={() => navigate("/")}
                >
                  <Button
                    color="inherit"
                    className={`nav-button ${isActive("/") ? "active" : ""}`}
                  >
                    Home
                  </Button>
                </div>

                <div
                  className={`nav-section ${
                    [
                      "/human-capital-solutions",
                      "/instructor-led-trainings",
                      "/on-demand-virtual-labs",
                    ].some((path) => location.pathname.startsWith(path))
                      ? "active"
                      : ""
                  }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ position: "relative" }}
                >
                  <Button
                    color="inherit"
                    className={`nav-button ${
                      [
                        "/human-capital-solutions",
                        "/instructor-led-trainings",
                        "/on-demand-virtual-labs",
                      ].some((path) => location.pathname.startsWith(path))
                        ? "active"
                        : ""
                    }`}
                  >
                    Solutions
                  </Button>
                  <Menu
                    id="solutions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    className="dropdown-menu"
                    MenuListProps={{
                      onMouseEnter: () => setAnchorEl(anchorEl),
                      onMouseLeave: handleMouseLeave,
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/human-capital-solutions")
                      }
                    >
                      Human Capital Solutions
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/instructor-led-trainings")
                      }
                    >
                      Instructor-Led Trainings
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleMenuItemClick("/on-demand-virtual-labs")
                      }
                    >
                      On-Demand Virtual Labs
                    </MenuItem>
                  </Menu>
                </div>

                <div
                  className={`nav-section ${
                    isActive("/gen-ai-courses") ? "active" : ""
                  }`}
                  onClick={() => navigate("/gen-ai-courses")}
                >
                  <Button
                    color="inherit"
                    className={`nav-button ${
                      isActive("/gen-ai-courses") ? "active" : ""
                    }`}
                  >
                    GenAI Courses
                  </Button>
                </div>
                <div
                  className={`nav-section ${
                    isActive("/leading-life") ? "active" : ""
                  }`}
                  onClick={() => navigate("/leading-life")}
                >
                  <Button
                    color="inherit"
                    className={`nav-button ${
                      isActive("/leading-life") ? "active" : ""
                    }`}
                  >
                    Leading Life!
                  </Button>
                </div>
              </div>
              {isTab ? (
                <div onClick={() => setIsOverlayOpen(true)}>
                  <SearchIcon />
                </div>
              ) : (
                <div className="header-search">
                  <div className="search-icon-wrapper">
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search courses"
                    inputProps={{ "aria-label": "search" }}
                    className={`search-input ${isActive ? "active" : ""}`}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={handleSearchSubmit}
                  />
                </div>
              )}

              <button onClick={scrollToGetInTouch} className="GetInTouch">
                Get in touch
              </button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
