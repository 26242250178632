import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  MenuItem,
  TextField,
  Button,
  IconButton,
  Select,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import "./contactUs.css";
import emailjs from "emailjs-com";
import countryList from "country-list-with-dial-code-and-flag";

const ContactUsModal = ({ isOpen, onClose, selectedCard = false }) => {
  const initialFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneCode: "+1", // Default country code
    phoneNumber: "",
    companyName: "",
    message: "",
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validation for specific fields
    if (["firstName", "lastName"].includes(name)) {
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Only alphabets are allowed" });
      }
    } else if (name === "email") {
      const emailRegex = /^(?!.*@(gmail\.com|hotmail\.com|yahoo\.com)$)([a-zA-Z0-9._%+-]+)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      setFormValues({ ...formValues, [name]: value });
      if (
        emailRegex.test(value) &&
        value !== ""
      ) {
        setErrors({ ...errors, email: "Invalid email format" });
      } else {
        setErrors({ ...errors, email: "" });
      }
    } else if (name === "phoneNumber") {
      if (/^[0-9]*$/.test(value)) {
        setFormValues({ ...formValues, [name]: value });
        if (value.length !== 10 && value.length > 0) {
          setErrors({
            ...errors,
            phoneNumber: "Phone number must be 10 digits",
          });
        } else {
          setErrors({ ...errors, phoneNumber: "" });
        }
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
      if (!value.trim()) {
        setErrors({ ...errors, [name]: "This field is required" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
  };

  const validCountryCodes = countryList.getAll().map((country) => ({
    dial_code: country.dial_code,
    name: country.name,
    code: country.code, // Include flag
  }));

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key].trim() && key !== "message") {
        newErrors[key] = "This field is required";
      }
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      // Construct emailParams including selectedCard if it's available
      const emailParams = {
        from_name: `${formValues.firstName} ${formValues.lastName}`,
        from_email: formValues.email,
        company_name: formValues.companyName,
        phone_number: `${formValues.phoneCode} ${formValues.phoneNumber}`,
        message: formValues.message || "No additional message provided.",
        selected_card: selectedCard || "N/A", // Default to 'N/A' if not provided
        form_type: "Contact Us", // Add the form type here
      };

      emailjs
        .send(
          "service_hgkcxrq", // Your EmailJS Service ID
          "template_9ucywzj", // Your EmailJS Template ID
          emailParams,
          "kHDcuQ_-7ij2FZ2hf" // Your EmailJS Public Key
        )
        .then(
          (response) => {
            console.log(
              "Email sent successfully:",
              response.status,
              response.text
            );
            alert("Form submitted successfully!");
            setFormValues(initialFormValues); // Reset form values
          },
          (error) => {
            console.error("Failed to send email:", error);
            alert("Failed to send the form. Please try again later.");
          }
        );
    }
  };

  const handleClose = () => {
    setFormValues(initialFormValues); // Reset form values
    setErrors({}); // Reset errors
    onClose(); // Close the modal
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className="modal__container">
        <Box className="modal-header">
          <Typography variant="h6" className="modal-title">
            Contact Us
          </Typography>
          <IconButton onClick={handleClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography className="modal-description">
          Please help us by filling the following details to provide you more
          information.
        </Typography>

        <Box
          component="form"
          className="form-container"
          onSubmit={handleSubmit}
        >
          {/* Prepopulated Field */}

          {selectedCard == null ? null : (
            <TextField
              value={selectedCard || ""}
              variant="outlined"
              fullWidth
              className="form-input"
              disabled
            />
          )}

          {/* First and Last Name */}
          <Box className="name-fields">
            <TextField
              label="First name"
              name="firstName"
              variant="outlined"
              fullWidth
              className="form-input"
              value={formValues.firstName}
              onChange={handleInputChange}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
            <TextField
              label="Last name"
              name="lastName"
              variant="outlined"
              fullWidth
              className="form-input"
              value={formValues.lastName}
              onChange={handleInputChange}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
          </Box>

          {/* Work Email */}
          <TextField
            label="Work Email"
            name="email"
            variant="outlined"
            fullWidth
            className="form-input"
            value={formValues.email}
            onChange={handleInputChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />

          {/* Company Name */}
          <TextField
            label="Company name"
            name="companyName"
            variant="outlined"
            fullWidth
            className="form-input"
            value={formValues.companyName}
            onChange={handleInputChange}
            error={Boolean(errors.companyName)}
            helperText={errors.companyName}
          />

          <Box className="phone-fields">
            
            <Select
              className="country-code"
              labelId="phone-code-label"
              value={formValues.phoneCode}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                    width: "250px",
                  },
                },
              }}
              onChange={(e) =>
                setFormValues({ ...formValues, phoneCode: e.target.value })
              }
              label="Code"
            >
              {validCountryCodes.map(({ dial_code, name, code }) => {
                const country = window.CountryList.findOneByCountryCode(code);
                let flagSvg = null;

                if (country && window.CountryFlagSvg[country.code]) {
                  // Parse the SVG string into a DOM element
                  const parser = new DOMParser();
                  const svgDoc = parser.parseFromString(
                    window.CountryFlagSvg[country.code],
                    "image/svg+xml"
                  );
                  const svgElement = svgDoc.documentElement;

                  // Add necessary attributes for proper rendering
                  svgElement.setAttribute("width", "20");
                  svgElement.setAttribute("height", "15");
                  svgElement.setAttribute("class", "inline-block");

                  flagSvg = (
                    <span
                      className="mr-2 inline-block align-middle"
                      dangerouslySetInnerHTML={{
                        __html: svgElement.outerHTML,
                      }}
                    />
                  );
                }

                return (
                  <MenuItem key={dial_code} value={dial_code} sx = {{width: "1000px !important"}}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {flagSvg}
                      <span>{`${name} (${dial_code})`}</span>
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
            {/* Phone Number Input */}
            <TextField
              label="Phone number"
              name="phoneNumber"
              variant="outlined"
              fullWidth
              className="textfield"
              value={formValues.phoneNumber}
              onChange={handleInputChange}
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
            />
          </Box>

          {/* Message */}
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            value={formValues.message}
            onChange={handleInputChange}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "#0DAFFC", marginTop: "1rem" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactUsModal;
