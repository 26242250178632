import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  CardActions,
  Pagination,
} from "@mui/material";
// import InstructorLedTraining from "../assests/images/industryLedTraining.png";
import { useLocation } from "react-router-dom";
import ContactUsModal from "./contactUs";
import "./InstructorLedTrainings.css";
import { Puff } from 'react-loader-spinner';


function InstructorLedTrainings({ data }) {
  const [categories, setCategories] = useState([]); // All categories
  const [isModalOpen, setIsModalOpen] = useState(false); // To control modal visibility
  const [selectedCard, setSelectedCard] = useState(""); // To pass the selected course name to the modal

  // const [courses, setCourses] = useState([]); // Courses to display
  const [filteredCourses, setFilteredCourses] = useState([]); // Filtered courses
  const [availablePartners, setAvailablePartners] = useState([]); // Partners (brands)
  const [selectedPartners, setSelectedPartners] = useState([]); // Selected filters
  const [selectedCategories, setSelectedCategories] = useState([]); // Selected categories
  const [searchValue, setSearchValue] = useState(""); // Local search bar value
  const [showCertifications, setShowCertifications] = useState(true);
  const [showTrainings, setShowTrainings] = useState(true);
  const [fullCourses, setFullCourses] = useState(data);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const itemsPerPage = 10; // Number of courses per page
  const [loading, setLoading] = useState(true); // Track loading state

  const location = useLocation(); // Access global search data

  const processCategories = (courses) => {
    const uniqueCategories = new Set(
      courses.filter(course => course.finalCategory && course.finalCategory.trim() !== "")
             .map(course => course.finalCategory.trim())
    );
    
    const categoryArray = Array.from(uniqueCategories);
    setCategories(categoryArray);

    if(selectedCategories.length === 0){
      const initialCategory = categoryArray[0];
      setSelectedCategories([initialCategory]);

      const filtered = courses.filter(course => course.finalCategory === initialCategory);
      setFilteredCourses(filtered);

      setAvailablePartners(new Set(filtered.map(course => course.subCategory)));

    }
  
    if (location.state && location.state.searchedData) {
      const { searchedData, searchTerm } = location.state;
      setFilteredCourses(searchedData);
      setSearchValue(searchTerm);
      setSelectedCategories([]);
      setAvailablePartners(new Set(searchedData.map(course => course.subCategory)));
    } 

    
  };

  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const cachedData = localStorage.getItem("coursesData");
        let dataToUse;
        
        if (cachedData && JSON.parse(cachedData).length !== 0) {
          dataToUse = JSON.parse(cachedData);
        } else {
          const response = await fetch("https://script.google.com/macros/s/AKfycbzJBqNZtKo5zHW7U3ty-QNnYCA_X20KcsZpPSa2Z9c_C81qEWp8FJFQqe_jp_zaOU9Hvg/exec");
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          dataToUse = await response.json();
          localStorage.setItem("coursesData", JSON.stringify(dataToUse));
        }
        setFullCourses(dataToUse);
        processCategories(dataToUse);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  },[location],processCategories);
  
  const handleContactUsClick = (courseName) => {
    setSelectedCard(courseName); // Set the selected course name
    setIsModalOpen(true); // Open the modal
  };

  const handleCategoryClick = (category) => {
    setSearchValue(""); // Clear the local search bar when a tag is clicked

    // If the clicked category is already selected, clear the selection
    if (selectedCategories.includes(category)) {
      setSelectedCategories([]); // Deselect the category
      setFilteredCourses([]); // Show all courses when no category is selected
      setAvailablePartners([]);
    } else {
      // Set the clicked category as the only selected category
      setSelectedCategories([category]);
      // Filter courses and update available partners
      const filtered = fullCourses.filter(
        (course) => course.finalCategory === category
      );
      setFilteredCourses(filtered);
      setAvailablePartners(
        Array.from(new Set(filtered.map((course) => course.subCategory)))
      );
    }
    setCurrentPage(1)
  };

  const handleFilterChange = (partner) => {
    if (selectedPartners.includes(partner)) {
      setSelectedPartners(selectedPartners.filter((p) => p !== partner));
    } else {
      setSelectedPartners([...selectedPartners, partner]);
    }
    setCurrentPage(1)

  };

  const handleCheckboxChange = (type) => {
    if (type === "Certifications") {
      setShowCertifications(!showCertifications);
    } else if (type === "Trainings") {
      setShowTrainings(!showTrainings);
    }
    setCurrentPage(1)

  };

  const handleLocalSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setSelectedCategories([]);
    // Filter courses based on search term
    if (!value) {
      setFilteredCourses([]);
      setAvailablePartners([]);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = fullCourses.filter((course) =>
        Object.values(course).some((field) =>
          String(field).toLowerCase().includes(lowercasedValue)
        )
      );

      setFilteredCourses(filtered);

      // Update available partners for the filtered courses
      const filteredPartners = Array.from(
        new Set(filtered.map((course) => course.subCategory))
      );
      setAvailablePartners(filteredPartners);
    }
    setCurrentPage(1)

  };

  // Filter the displayed courses based on selected partners, categories, and checkboxes
  console.log(filteredCourses)
  const displayedCourses = filteredCourses.filter((course) => {
    if (
      selectedPartners.length > 0 &&
      !selectedPartners.includes(course.subCategory)
    )
      return false;
    if (
      showCertifications &&
      (course.courseType === "Certification" ||
        course.courseType === "Training & Certification")
    ) {
      return true;
    }

    // If 'Trainings' checkbox is checked, filter out courses that are 'Course/Training' or 'Training & Certification'
    if (
      showTrainings &&
      (course.courseType === "Course/Training" ||
        course.courseType === "Training & Certification")
    ) {
      return true;
    }

    return false;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCourses = displayedCourses.slice(startIndex, endIndex);
  if (loading) {
    return (
      <div  className = "loader">
       
       <Puff
          color="#00BFFF"
          height={100}
          width={100}
          visible={loading}
        />  
           </div>
    );
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        {/* Image Section */}
        <div className="training-intro-section">
          <div className="InstructorLedTraining">
            <img src={"https://i.ibb.co/LD7kBRgY/industry-Led-Training.png"} alt="Instructor Led Training"  loading="lazy"/>
          </div>
          <div className="text-section-">
            <span className="Instructor_led_trainings">Instructor led </span>{" "}
            <span className="trainings">trainings</span>
            <br />
            <span className="text-section-span-training">
              Learning Tailored to Your{" "}
              <span className="Organization">
                Organization Schedule and Style{" "}
              </span>   
              Flexible options to suit your time and preferred mode -anytime,
              anywhere
            </span>
          </div>
        </div>

        <Box className="Browse-Section">
          {/* Title Section */}
          <h4 className="title">Browse through our trending courses</h4>
          {/* Categories Section */}
          <Box className="search-section">
            {categories.map((category, index) => {
              return (
                <Button
                  key={index}
                  className={`category-button ${
                    selectedCategories.includes(category) ? "active" : ""
                  }`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </Button>
              );
            })}
          </Box>
        </Box>

        <Box className="filter-section">
          <TextField
            variant="outlined"
            placeholder="Search courses"
            className="search-bar"
            value={searchValue}
            onChange={handleLocalSearchChange}
            sx={{
              borderRadius: "25px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "25px",
                padding: "0px 15px",
                background: "#fff",
              },
              "& .MuiOutlinedInput-root:hover": {
                borderColor: "#007FFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc",
              },
            }}
          />

          <Box className="checkbox-section">
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCertifications}
                  onChange={() => handleCheckboxChange("Certifications")}
                />
              }
              label="Certifications"
              className="filter-checkbox"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTrainings}
                  onChange={() => handleCheckboxChange("Trainings")}
                />
              }
              label="Trainings"
              className="filter-checkbox"
            />
          </Box>
        </Box>
        <Box className="result-section">
          {/* Left Section: Filters */}
          <Box sx={{ pr: 2, borderRight: "1px solid #ccc" }}>
            <Box className="filter-brands-section">
              <Typography variant="h6" sx={{ mb: 2, padding:"5px" }}>
                Filters
              </Typography>
              <Button
                onClick={() => {
                  setSelectedPartners([]); // Clear only the selected checkboxes
                }}
                variant="text"
              >
                Clear all
              </Button>
            </Box>

            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1,padding:"5px" }}>
              Brands
            </Typography>
            <Box className="checkboxs">
              {Array.from(availablePartners).map((partner, index) => (
                <div className="checkboxs-content">
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedPartners.includes(partner)}
                        onChange={() => handleFilterChange(partner)}
                      />
                    }
                    label={partner}
                  />
                  <p>
                    {
                      filteredCourses.filter(
                        (course) => course.subCategory === partner
                      ).length
                    }
                  </p>
                </div>
              ))}
            </Box>
          </Box>

          {/* Right Section: Cards */}
          <Box className="courses-section">
            <Typography variant="h6" sx={{ mb: 2, padding:"10px"}}>
              {displayedCourses.length} Results found
            </Typography>
            <Box className="courses-grid">
              {paginatedCourses.map((course, index) => (
                <Card key={index} className="course-card">
                  <CardContent>
                    {/* Partner Logo */}
                    <Typography variant="body2" color="text.secondary">
                      {course.subCategory || "Partner"}
                    </Typography>
                    {/* Course Title */}
                    <Typography variant="h6" component="div">
                      {course.courseName}
                    </Typography>
                    {/* Category */}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        background: "#f0f0f0",
                        display: "inline-block",
                        borderRadius: "15px",
                        padding: "2px 8px",
                        marginBottom: "10px",
                      }}
                    >
                      {course.finalCategory}
                    </Typography>
                    {/* Description */}
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontWeight: 400,
                        lineHeight: 1.5,
                        color: "#333333",
                        letterSpacing: "0.015em",
                      }}
                    >
                      {course["Course Description"] || ""}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        backgroundColor: "#0056C3",
                        height: "42px",
                        width: "100px",
                        fontSize: "15px",
                        color: "#EEFAFF",
                        fontFamily: "'Nunito', sans-serif",
                        "&:hover": {
                          backgroundColor: "#004a9f", // Darker shade for hover
                          color: "#ffffff", // Optional: change text color on hover
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Optional: add shadow effect on hover
                        },
                      }}
                      onClick={() => handleContactUsClick(course.courseName)} // Pass the course name
                    >
                      Contact us
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
        <Pagination
          count={Math.ceil(displayedCourses.length / itemsPerPage)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        />

        <ContactUsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)} // Close the modal
          selectedCard={selectedCard} // Pass the selected course name
        />
      </Box>
    );
  }
}

export default InstructorLedTrainings;
