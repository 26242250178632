import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./HumanCapitalSolutions.css";
// import AlignAssess_Image from "../assests/images/AlignAssess_Image.png";
// import Assess_Image from "../assests/images/Assess_Image.png";
import HumanCapitalSolutionsEmpower from "../assests/images/HumanCapitalSolutionsEmpower.png";
import ContactUs from "./contactUs";
// import CapanilityBuildingImage from "../assests/images/HumanCapitalSolutions.png";
function HumanCapitalSolutions() {
  const [activeButton, setActiveButton] = useState("Align & Assess");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // State for mobile view
  const [isExpanded, setIsExpanded] = useState(false);

  const stats = [
    { value: "1M+", label: "Aligned goals" },
    { value: "1.5M+", label: "Assessed Talents" },
    { value: "3M+", label: "Empowered Talents" },
    { value: "11M+", label: "Developed Skills" },
    { value: "9M+", label: "Trained Technology" },
  ];
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480); // Adjust breakpoint as needed
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Listen for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener
    };
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* Fullscreen Image Section */}
      <div className="container">
        <div className="image-section-img">
          <img src={"https://i.ibb.co/3y9fh11K/Human-Capital-Solutions.png"} alt="Empower Organizations" loading="lazy" />
        </div>
        <div className="text-section-">
          <h1 className="text-section-h1">
            Capability Building to{" "}
            <span className="text-section-span">Empower Organizations</span>
          </h1>
        </div>
      </div>

      {/* Section 2 */}
      <Box className="stats-section">
        <div className="mobile-view-stats">
          {stats.map((stat, index) => (
            <div className="stat-item" key={index}>
              <div className="stat-value">{stat.value}</div>
              <div className="stat-label">{stat.label}</div>
            </div>
          ))}
        </div>{" "}
        <Box className="stats-img"></Box>
      </Box>

      {/* Section 3 */}
      {isMobile ? (
        <>
          {isExpanded ? (
            <Box
              className={`buttons-section ${isExpanded ? "expanded" : ""}`}
              alignItems="start"
            >
              <Box>
                {["Align & Assess", "Empower & Develop"].map((button) => (
                  <Button
                    key={button}
                    variant={activeButton === button ? "contained" : "outlined"}
                    color={activeButton === button ? "primary" : "inherit"}
                    onClick={() => {
                      console.log("hello");
                      setActiveButton(button);
                      setIsExpanded(false);
                    }}
                    className={`buttons-mobile ${
                      activeButton === button ? "active" : ""
                    }`}
                  >
                    {button}
                  </Button>
                ))}
              </Box>
              <IconButton color="primary" onClick={() => setIsExpanded(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <Box
              alignItems="center"
              justifyContent="space-between"
              className="button-section-mobile"
            >
              <Button variant="contained" className="buttons-mobile">
                {activeButton}
              </Button>
              <IconButton color="primary" onClick={() => setIsExpanded(true)}>
                <MenuIcon />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Box className="button-container-humanCapital">
          {["Align & Assess", "Empower & Develop"].map((button) => (
            <Button
              key={button}
              className={`custom-button ${
                activeButton === button ? "active" : ""
              }`}
              onClick={() => setActiveButton(button)}
            >
              {button}
            </Button>
          ))}
        </Box>
      )}

      {/* Conditional Rendering for Section 4 */}
      {activeButton === "Align & Assess" ? (
        <>
          <Box
            className={`image-section-HumanCapital ${
              isExpanded ? "expanded" : ""
            }`}
          >
            <div className="Empower-section">
              <h2 className="body_heading">
                Establish clear, inspiring, and measurable goals that align
                individual, team, and organizational efforts toward strategic
                priorities. 
              </h2>
              <img
                src={"https://i.ibb.co/6ch2x1rp/Align-Assess-Image.png"}
                alt="Align & Assess"
                className="section-image"
                loading="lazy"
              />
            </div>
            <div
              style={{
                borderBottom: "2px dashed #b8d0f0",
                height: "1px",
                width: "80%",
                margin: "5%",
              }}
            />
            <div className="Empower-section">
              <h2 className="body_heading">
                Assess Technical and Behavioral skills and competencies to
                benchmark and create customized learning paths
              </h2>
              <img
                src={"https://i.ibb.co/96hRHjW/Assess-Image.png"}
                alt="Empower & Develop"
                className="section-image"
                loading="lazy"
              />
            </div>{" "}
            <Box className="industries-section">
              <Typography variant="h4" className="industries-heading">
                Industries We Empower:
              </Typography>
              <Box className="industries-tags">
                {[
                  "Technology",
                  "Government",
                  "Finance",
                  "Retail",
                  "Construction",
                  "Manufacturing",
                  "Transportation / Logistics",
                  "Services",
                  "Utilities & Energy",
                ].map((industry, index) => (
                  <Box key={index} className="industry-tag">
                    {industry}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box className="why-choose-us-section">
            <Box className="content-box">
              <Typography variant="h4" className="section-heading">
                Why Choose Us?
              </Typography>
              <ul className="benefits-list-">
                <li>Proven results across diverse industries.</li>
                <li>
                  Tools designed to integrate seamlessly with your current
                  systems.
                </li>
                <li>
                  Customizable solutions to match your organizational needs.
                </li>
              </ul>
              <Typography variant="body1" className="cta-text">
                Discover how our tailored OKR and Assessment solutions can
                elevate your team performance.
              </Typography>
              <Box className="cta-button-container">
                <Button
                  variant="contained"
                  className="cta-button"
                  onClick={() => handleModalOpen()}
                >
                  Talk to us
                </Button>
              </Box>
            </Box>
            <ContactUs
              isOpen={isModalOpen}
              onClose={handleModalClose}
              selectedCard={"Align & Assess"}
            />
          </Box>
        </>
      ) : (
        <>
          <div className={`Empower-section ${isExpanded ? "expanded" : ""}`}>
            <h2 className="body_heading">
              Empower and develop talent at every level through tailored
              learning programs that drive growth and align with strategic
              priorities.
            </h2>
            <img
              src={HumanCapitalSolutionsEmpower}
              alt="Empower and Develop"
              className="Empower-section-image"
              loading="lazy"
            />
          </div>
          <Box className="why-choose-us-section">
            <Box className="content-box">
              <Typography variant="h4" className="section-heading">
                Why Choose Us?
              </Typography>
              <ul>
                <li>
                  <strong>Holistic Development:</strong> Programs designed to
                  empower employees at all levels, from entry-level talent to
                  C-suite executives.
                </li>
                <li>
                  <strong>Tailored Approach:</strong> Customized interventions
                  that address unique organizational and individual needs.
                </li>
                <li>
                  <strong>Strategic Growth:</strong> Alignment of development
                  goals with business priorities for measurable outcomes.
                </li>
                <li>
                  <strong>Comprehensive Offerings:</strong> Leadership coaching,
                  technical skill enhancement, and behavioral training all in
                  one place.
                </li>
                <li>
                  <strong>Expert-Driven:</strong> Leverage insights from
                  seasoned mentors and globally recognized certification
                  programs.
                </li>
              </ul>
              <Typography variant="body1" className="cta-text">
                <span style={{ color: "#007FFF", cursor: "pointer" }}>
                  Empower your workforce today—start your journey with us!
                </span>
              </Typography>
              <Box className="cta-button-container">
                <Button
                  variant="contained"
                  className="cta-button"
                  onClick={() => handleModalOpen()}
                >
                  Talk to us
                </Button>
              </Box>
            </Box>

            <ContactUs
              isOpen={isModalOpen}
              onClose={handleModalClose}
              selectedCard="Empower & Develop"
            />
          </Box>
        </>
      )}

      {/* Section 5 */}
    </div>
  );
}

export default HumanCapitalSolutions;
