import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  IconButton,
  Select,

} from "@mui/material";

import countryList from "country-list-with-dial-code-and-flag";
import CloseIcon from "@mui/icons-material/Close";
import "./ContactUsLabs.css";
import emailjs from "emailjs-com"; // Import EmailJS

const ContactFormModal = ({ isOpen, onClose, selectedCard }) => {
  const initialFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    skillOrCompetency: "",
    duration: "",
    numberOfUsers: "",
    message: "",
    phoneCode: "+1", // Default country code

  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validation for specific fields
    if (["firstName", "lastName"].includes(name)) {
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({ ...errors, [name]: "Only alphabets are allowed" });
      }
    } else if (name === "email") {
      const emailRegex = /^(?!.*@(gmail\.com|hotmail\.com|yahoo\.com)$)([a-zA-Z0-9._%+-]+)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      setFormValues({ ...formValues, [name]: value });
      if (
        emailRegex.test(value) &&
        value !== ""
      ) {
        setErrors({ ...errors, email: "Invalid email format" });
      } else {
        setErrors({ ...errors, email: "" });
      }
    } else if (name === "phoneNumber") {
      if (/^[0-9]*$/.test(value)) {
        setFormValues({ ...formValues, [name]: value });
        if (value.length !== 10 && value.length > 0) {
          setErrors({
            ...errors,
            phoneNumber: "Phone number must be 10 digits",
          });
        } else {
          setErrors({ ...errors, phoneNumber: "" });
        }
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
      if (!value.trim()) {
        setErrors({ ...errors, [name]: "This field is required" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
  };

  const validCountryCodes = countryList.getAll().map((country) => ({
    dial_code: country.dial_code,
    name: country.name,
    code: country.code, // Include flag
  }));

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key].trim() && key !== "message") {
        newErrors[key] = "This field is required";
      }
    });


    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Construct emailParams including selectedCard if it's available
      const emailParams = {
        from_name: `${formValues.firstName} ${formValues.lastName}`,
        from_email: formValues.email,
        company_name: formValues.companyName,
        phone_number: `${formValues.phoneCode} ${formValues.phoneNumber}`,
        skill_or_competency: formValues.skillOrCompetency || "N/A",
        duration: formValues.duration || "N/A",
        number_of_users: formValues.numberOfUsers || "N/A",
        message: formValues.message || "No additional message provided.",
        selected_card: selectedCard || "N/A", // Add selectedCard or default to 'N/A'
        form_type: "Contact US - Virtual Labs", // Add the form type here

      };



      emailjs
        .send(
          "service_hgkcxrq", // Your EmailJS Service ID
          "template_mskt8uf", // Your EmailJS Template ID
          emailParams,
          "kHDcuQ_-7ij2FZ2hf" // Your EmailJS Public Key
        )
        .then(
          (response) => {
            console.log("Email sent successfully:", response.status, response.text);
            alert("Form submitted successfully!");
            setFormValues(initialFormValues); // Reset form values
          },
          (error) => {
            console.error("Failed to send email:", error);
            alert("Failed to send the form. Please try again later.");
          }
        );
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className="modal-container">
        <Box className="modal-header">
          <Typography variant="h6" className="modal-title">
            Contact Us
          </Typography>
          <IconButton onClick={onClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography className="modal-description">
          Please help us by filling the following details to provide you more
          information.
        </Typography>

        <Box component="form" className="form-container" onSubmit={handleSubmit}>
          {/* Prepopulated Field */}
          {selectedCard ? (
            <TextField
              label="Selected Card"
              value={selectedCard || ""}
              variant="outlined"
              fullWidth
              className="form-input"
              disabled
            />
          ) : null}

          {/* First and Last Name */}
          <Box className="name-fields">
            <TextField
              label="First name"
              name="firstName"
              variant="outlined"
              fullWidth
              className="form-input"
              value={formValues.firstName}
              onChange={handleInputChange}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
            <TextField
              label="Last name"
              name="lastName"
              variant="outlined"
              fullWidth
              className="form-input"
              value={formValues.lastName}
              onChange={handleInputChange}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
          </Box>

          {/* Work Email */}
          <TextField
            label="Work Email"
            name="email"
            variant="outlined"
            fullWidth
            className="form-input"
            value={formValues.email}
            onChange={handleInputChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />

          {/* Company Name */}
          <TextField
            label="Company name"
            name="companyName"
            variant="outlined"
            fullWidth
            className="form-input"
            value={formValues.companyName}
            onChange={handleInputChange}
            error={Boolean(errors.companyName)}
            helperText={errors.companyName}
          />


          <Box className="phone-fields">
            {/* Country Code Dropdown */}

            <Select
                className="country-code"
                labelId="phone-code-label"
                value={formValues.phoneCode}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                      width: 300,
                    },
                  },
                }}
                onChange={(e) =>
                  setFormValues({ ...formValues, phoneCode: e.target.value })
                }
                label="Code"
              >
                {validCountryCodes.map(({ dial_code, name, code }) => {
                  const country = window.CountryList.findOneByCountryCode(code);
                  let flagSvg = null;

                  if (country && window.CountryFlagSvg[country.code]) {
                    // Parse the SVG string into a DOM element
                    const parser = new DOMParser();
                    const svgDoc = parser.parseFromString(window.CountryFlagSvg[country.code], 'image/svg+xml');
                    const svgElement = svgDoc.documentElement;

                    // Add necessary attributes for proper rendering
                    svgElement.setAttribute('width', '20');
                    svgElement.setAttribute('height', '15');
                    svgElement.setAttribute('class', 'inline-block');

                    flagSvg = (
                      <span
                        className="mr-2 inline-block align-middle"
                        dangerouslySetInnerHTML={{
                          __html: svgElement.outerHTML
                        }}
                      />
                    );
                  }

                  return (
                    <MenuItem key={dial_code} value={dial_code}>
                      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {flagSvg}
                        <span >{`${name} (${dial_code})`}</span>
                      </div>

                    </MenuItem>
                  );
                })}


              </Select>

            {/* Phone Number Input */}
            <TextField
              label="Phone number"
              name="phoneNumber"
              variant="outlined"
              fullWidth
              className="textfield"
              value={formValues.phoneNumber}
              onChange={handleInputChange}
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
            />
          </Box>

          {/* Skill or Competencies */}
          <TextField
            label="Skill or competencies you are looking for..."
            name="skillOrCompetency"
            variant="outlined"
            select
            fullWidth
            className="form-input"
            value={formValues.skillOrCompetency}
            onChange={handleInputChange}
            error={Boolean(errors.skillOrCompetency)}
            helperText={errors.skillOrCompetency}
          >
            <MenuItem value="Skill 1">Skill 1</MenuItem>
            <MenuItem value="Skill 2">Skill 2</MenuItem>
            <MenuItem value="Skill 3">Skill 3</MenuItem>
          </TextField>

          {/* Duration */}
          <TextField
            label="Select duration..."
            name="duration"
            variant="outlined"
            select
            fullWidth
            className="form-input"
            value={formValues.duration}
            onChange={handleInputChange}
            error={Boolean(errors.duration)}
            helperText={errors.duration}
          >
            <MenuItem value="1 month">1 month</MenuItem>
            <MenuItem value="3 months">3 months</MenuItem>
            <MenuItem value="6 months">6 months</MenuItem>
          </TextField>

          {/* Number of Users */}
          <TextField
            label="Number of users..."
            name="numberOfUsers"
            variant="outlined"
            select
            fullWidth
            className="form-input"
            value={formValues.numberOfUsers}
            onChange={handleInputChange}
            error={Boolean(errors.numberOfUsers)}
            helperText={errors.numberOfUsers}
          >
            <MenuItem value="1-10">1-10</MenuItem>
            <MenuItem value="11-50">11-50</MenuItem>
            <MenuItem value="51-100">51-100</MenuItem>
          </TextField>

          {/* Message */}
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            multiline
            fullWidth
            value={formValues.message}
            onChange={handleInputChange}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "#0DAFFC", marginTop: "1rem" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactFormModal;
