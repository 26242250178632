import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import "./OnDemandVirtualLabs.css";
import icon from "../assests/images/sandbox_icon.png";
import ContactUsLabs from "./ContactUsLabs"; // Import the ContactFormModal component

function OnDemandVirtualLabs() {

  const [currentIndex, setCurrentIndex] = useState(0); // For dynamic text
  const texts = ["Practice", "Simulation", "Experimentation", "Hackathons"]; // Dynamic text options
  const [selectedCard, setSelectedCard] = useState(""); // State to store the selected card name
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length); // Cycle through texts
    }, 3500); // Change text every 2.5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [texts.length]);
  const data = [
    {
      title: "Sandboxes paired for instructor led learning & content",
      description: "Sandboxes for pairing with Instructor learning and content from various partners.",
    },
    {
      title: "Experimentation with new technologies",
      description: "Seamlessly test-drive and integrate cutting-edge tech in secure sandbox environments helping roll out of cutting-edge features and meet customer requirements.",
    },
    {
      title: "Simulation of customer environments",
      description: "Identify and resolve bugs in simulated customer environments, ensuring flawless deployments.",
    },
    {
      title: "Hackathons & POCs",
      description: "Scalable sandbox environments with tools and APIs for collaborative innovation and transformative solutions during hackathons.",
    },
    {
      title: "Customer enablement and certification",
      description: "Structured sandbox for guided learning and assessments, empowering customers with essential skills and certifications.",
    },
    {
      title: "Dive in-depth to track learning progress",
      description: "Challenge employees with real-world scenarios, tracking and fostering measurable skills development.",
    },
  ];

  // Handle modal open and set the selected card
  const handleOpenModal = (title) => {
    setSelectedCard(title);
    setIsModalOpen(true);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCard("");
  };

  return (
    <Box className="virtual-labs-container">
      <Box className="virtual-labs-home-section">
        <h4 className="dynamic-text-labs">
          {"Real-Time Environment for "}<br />
          <span className="highlight-text-labs">{texts[currentIndex]}</span>
          <span className="repeat-text">{" Anytime, Anywhere"}</span>
        </h4>
      </Box>

      {/* Section 2 */}
      <Box className="virtual-labs-section benefits-section">
        <Typography variant="body1" className="benefits-intro">
          Our on-demand virtual labs provide flexible, hands-on learning
          experiences accessible anytime, anywhere. Designed to fit within your
          schedule and budget, these labs offer safe practical training
          environments that enhance skill development and knowledge application.
        </Typography>
        <Box className="benefits-list">
          <Box>
            <ul className="benefits-column">
              <li>Hands-On Experience</li>
              <li>Accessibility</li>
              <li>Cost-Effective Learning</li>
              <li>Enhanced Engagement</li>
              <li>Safe Learning Environment</li>
              <li>Collaboration and Sharing</li>
              <li>Customized Learning Experiences</li>
              <li>Integration with Educational Content</li>
            </ul>
          </Box>
        </Box>
      </Box>

      {/* Section 3 */}
      <Box className="virtual-labs-sandbox-section">
        <Typography variant="h4" className="section-title">
          Sandboxes for your custom needs
        </Typography>
        <Box className="grid-container">
          {data.map((item, index) => (
            <Box key={index} className="grid-item">
              <img
                src={icon} // Replace this with your cube icon path
                alt="Sandbox Icon"
                className="icon"
                loading="lazy"
              />
              <Typography variant="h6" className="grid-title">
                {item.title}
              </Typography>
              <Typography variant="body2" className="grid-description">
                {item.description}
              </Typography>
              <Button
                variant="contained"
                className="contact-button"
                onClick={() => handleOpenModal(item.title)} // Open modal with card title
              >
                Contact us
              </Button>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Modal */}
      {isModalOpen && (
        <ContactUsLabs
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          selectedCard={selectedCard} // Pass the selected card title
        />
      )}
    </Box>
  );
}

export default OnDemandVirtualLabs;
