import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import GetInTouch from "./components/GetInTouch";
import Footer from "./components/Footer";
import HumanCapitalSolutions from "./components/HumanCapitalSolutions";
import InstructorLedTrainings from "./components/InstructorLedTrainings";
import OnDemandVirtualLabs from "./components/OnDemandVirtualLabs";
import GenAICourses from "./components/GenAiCourses"; // Import the new component
import ScrollToTop from "./components/scrollreset"; // Import the ScrollToTop component
import SearchOverlay from "./components/searchOverlay";
import LeadingLife from "./components/LeadingLife";

function App() {
  const contactRef = useRef(null); // Create a ref for the GetInTouch component
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Try to get cached data from localStorage
      try {
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbzJBqNZtKo5zHW7U3ty-QNnYCA_X20KcsZpPSa2Z9c_C81qEWp8FJFQqe_jp_zaOU9Hvg/exec"
        );
        const newData = await response.json();
        setLoading(false);
        setData(newData);
        localStorage.setItem("coursesData", JSON.stringify(newData)); // Store the data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filterBySearch = (searchTerm) => {
    if (!searchTerm) return data; // Return all data if no search term is provided

    const lowercasedTerm = searchTerm.toLowerCase();
    let resultData = data.filter((item) => {
      // Check if any key contains the search term
      return Object.values(item).some((value) =>
        String(value).toLowerCase().includes(lowercasedTerm)
      );
    });
    setSearchedData(resultData);

    return;
  };
  return (
    <Router>
      {isOverlayOpen ? (
        <SearchOverlay
          setIsOverlayOpen={setIsOverlayOpen}
          filterBySearch={filterBySearch}
          searchedData={searchedData}
        />
      ) : (
        <>
          <ScrollToTop />
          <Header
            contactRef={contactRef}
            filterBySearch={filterBySearch}
            searchedData={searchedData}
            setIsOverlayOpen={setIsOverlayOpen}
          />{" "}
          {/* Pass the ref to the Header */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/human-capital-solutions"
              element={<HumanCapitalSolutions />}
            />
            <Route
              path="/instructor-led-trainings"
              element={<InstructorLedTrainings data={data} loading={loading} />}
            />
            <Route
              path="/on-demand-virtual-labs"
              element={<OnDemandVirtualLabs />}
            />
            <Route path="/gen-ai-courses" element={<GenAICourses />} />
            <Route path="/leading-life" element={<LeadingLife />} />
          </Routes>
          <GetInTouch ref={contactRef} /> {/* Attach the ref */}
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
