import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  List,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Home.css";
import HeroSectionImage from "../assests/images/Home_Section_1.png";
// import IndustryLedTrainingImage from "../assests/images/Industry_led_Training.png";
// import LearningEnvironmentsImage from "../assests/images/Learning_Environments.png";
// import PeopleSolutionsImage from "../assests/images/People_Solutions.png";
import FlyFish from "../assests/images/BirdFly.gif";
// import Home_Section_2 from "../assests/images/Home_Section_2.png";
import MenuIcon from "@mui/icons-material/Menu"; // Hamburger menu icon
import CloseIcon from "@mui/icons-material/Close"; // Cross icon for collapsing
const texts = [
  "Future-Ready Workforce.",
  "Leaders from Within.",
  "Continuous Talent Pipeline.",
  "Project-Ready Talent.",
];
const contentMap = {
  "Human Capital Solutions": {
    heading: "Human Capital Solutions",
    image: "https://i.ibb.co/SX3MBxdP/People-Solutions.png",
    buttonLabel: "View details",
    route: "/human-capital-solutions", // Add route for navigation
  },
  "Instructor Led Training": {
    heading: "",
    points: [
      "Interactive Learning: Encourages active participation through discussions and hands-on activities.",
      "Immediate Feedback: Provides real-time responses to enhance understanding.",
      "Personalized Attention: Adapts content to individual needs and learning styles.",
      "Collaborative Environment: Fosters teamwork and idea exchange among learners.",
    ],
    description:
      "Instructor-Led Training (ILT) involves real-time instruction by an expert, delivered in person (ILT), virtually (VILT), or through a blended (ILT+VILT) approach. This method promotes interactive learning, immediate feedback, personalized instruction, and collaborative discussions.",
    image: "https://i.ibb.co/qw2wX03/Industry-led-Training.png",
    buttonLabel: "Explore our wide range of courses",
    route: "/instructor-led-trainings",
  },
  "Learning Environments": {
    heading: "On-Demand Virtual Labs",
    description:
      "Our on-demand virtual labs provide flexible, hands-on learning experiences accessible anytime, anywhere. Designed to fit within your schedule and budget, these labs offer safe practical training environments that enhance skill development and knowledge application.",
    points: [],
    image: "https://i.ibb.co/DPznJX6b/Learning-Environments.png",
    buttonLabel: "View details",
    route: "/on-demand-virtual-labs",
  },
};

function Home() {
  useEffect(() => {
    if (texts.length === 0) return; // Ensure the array isn't empty to avoid errors

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3500); // Change text every 2.5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false); // State to manage collapsible card
  const [activeButton, setActiveButton] = useState("Human Capital Solutions");
  const navigate = useNavigate(); // Use useNavigate for routing

  const buttons = Object.keys(contentMap);

  const handleButtonClick = () => {
    const route = contentMap[activeButton].route;
    if (route) {
      navigate(route); // Navigate to the specified route
    }
  };

  return (
    <div className="Home_Section">
      {/* Hero Section */}
      <div className="hero-section">
        <img
          src={HeroSectionImage}
          alt="LX Learning Hero Section"
          className="hero-image"
          loading="lazy"
        />
        <Typography className="dynamic-text">
          Empower Organizations to Build
          <span className="highlight-text">{"    " + texts[currentIndex]}</span>
        </Typography>
        <img className="Flyfish" src={FlyFish} alt="" loading="lazy"/>
      </div>
      <div className="org_tree_section">
        <div className="tree_header_section">
          {" "}
          <h4 className="tree_header">
            “Aligning diverse learning dimensions{" "}
            <span className="tree_span">
              {" "}
              <br />
              to drive results and transformation”
            </span>
          </h4>
        </div>

        <div style={{display : "flex", justifyContent : "center", alignItems : "center"}}> 
          <img
            src={"https://i.ibb.co/pjMRTWCZ/Business-Goals-tree.png"}
            alt="LX Learning Intro  section"
            className="intro_image"
            loading="lazy"
          />
        </div>
      </div>

      {/* Buttons Section */}
      {window.innerWidth <= 480 ? (
        <>
          {isExpanded ? (
            <Box
              className={`buttons-section ${isExpanded ? "expanded" : ""}`}
              alignItems="start"
            >
              <Box className="button-container-mobile">
                {buttons.map((button) => (
                  <Button
                    key={button}
                    variant={activeButton === button ? "contained" : "outlined"}
                    color={activeButton === button ? "primary" : "inherit"}
                    onClick={() => {
                      setActiveButton(button);
                      setIsExpanded(!isExpanded);
                    }}
                    className={`buttons-mobile ${
                      activeButton === button ? "active" : ""
                    }`}
                    sx={{ textTransform: "none" }}
                  >
                    {button}
                  </Button>
                ))}
              </Box>

              <IconButton
                color="primary"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
          ) : (
            <Box
              alignItems="center"
              justifyContent="space-between"
              className="button-section-mobile"
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none", flexGrow: 1 }}
                onClick={handleButtonClick}
                className="buttons-mobile"
              >
                {activeButton}
              </Button>
              <IconButton
                color="primary"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Container className="button-section">
          <Box className="button-container">
            {buttons.map((button) => (
              <Button
                key={button}
                variant={activeButton === button ? "contained" : "outlined"}
                color={activeButton === button ? "primary" : "inherit"}
                onClick={() => setActiveButton(button)}
                className={`button-card ${
                  activeButton === button ? "active" : ""
                }`}
                sx={{ textTransform: "none" }}
              >
                {button}
              </Button>
            ))}
          </Box>
        </Container>
      )}

      {/* Content Section */}
      <Container className="content-section">
        {activeButton === "Human Capital Solutions" ? (
          // Single section for "Human Capital Solutions"
          <Box
            className={`single-image-container ${isExpanded ? "expanded" : ""}`}
          >
            <Box className="vertical-container">
              <img
                src={contentMap[activeButton].image}
                alt={contentMap[activeButton].heading}
                className="full-width-image"
                loading="lazy"
              />
              <Button
                variant="contained"
                className="centered-button-Human-Capital-Solutions"
                onClick={handleButtonClick} // Call handleButtonClick on click
                sx={{ backgroundColor: "#0DAFFC" }}
              >
                {contentMap[activeButton].buttonLabel}
              </Button>
            </Box>
          </Box>
        ) : activeButton === "Instructor Led Training" ? (
          // Updated section for "Instructor Led Training"
          <Box
            className={`two-column-container ${isExpanded ? "expanded" : ""}`}
          >
            {/* Left Section: Text */}
            <Box className="text-section">
              <Typography
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontWeight: 600,
                }}
              >
                {contentMap[activeButton].heading}
              </Typography>
              <Typography
                variant="body1"
                className="content-body"
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontWeight: "700", // Increase fontWeight here
                  marginBottom: "1rem",
                  fontSize: "18px !important",
                }}
              >
                {contentMap[activeButton].description}
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontWeight: "400",
                  fontSize: "18px",
                  marginBottom: "-2.5%",
                  color: "#343434",
                }}
              >
                Benefits:
              </Typography>
              <List>
                {contentMap[activeButton].points.map((point, index) => {
                  const [boldText, ...restText] = point.split(":");
                  return (
                    <ul
                      key={index}
                      style={{
                        fontFamily: '"Nunito", sans-serif', // Apply font family to the whole list for consistency
                        fontWeight: "300",
                        color: "#343434",
                      }}
                    >
                      <li
                        style={{
                          fontSize: "16px",
                          padding: 0,
                          listStyleType: "disc",
                          lineHeight: 1.5,
                        }}
                      >
                        <strong>{boldText}:</strong> {restText.join(":")}
                      </li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontWeight: "400",
                  fontSize: "18px",
                  color: "#343434",
                  marginBottom: "5%",
                }}
              >
                Incorporating ILT/VLT/Blended can enhance employee development
                and organizational performance.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0DAFFC",
                  width: "300px",
                  fontWeight: "400 !important",
                  textTransform: "none",
                  marginBottom: "15%",
                  color: "#EEFAFF !important",
                }}
                onClick={handleButtonClick} // Call handleButtonClick on click
              >
                {contentMap[activeButton].buttonLabel}
              </Button>
            </Box>

            {/* Right Section: Image */}
            <Box className="image-section">
              <img
                src={contentMap[activeButton].image}
                alt={contentMap[activeButton].heading}
                className="responsive-image"
                loading="lazy"
              />
            </Box>
          </Box>
        ) : activeButton === "Learning Environments" ? (
          // Two sections for "Learning Environments"
          <Box
            className={`two-column-container ${isExpanded ? "expanded" : ""}`}
          >
            {/* Left Section: Text */}
            <Box className="text-section">
              <Typography
                variant="body1"
                className="content-body"
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontWeight: "700 !important",
                  fontSize: "18px",
                  marginBottom: "1.5rem",
                  lineHeight: "1.6",
                  color: "#333", // Neutral dark text colorss
                }}
              >
                {contentMap[activeButton].description}
              </Typography>
              <Button
                variant="contained"
                onClick={handleButtonClick} // Call handleButtonClick on click
                sx={{
                  backgroundColor: "#0DAFFC", // Button color
                  fontFamily: '"Nunito", sans-serif',
                  fontWeight: "400",
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "5px",
                  boxShadow: "none",
                  color: "#EEFAFF",
                }}
              >
                {contentMap[activeButton].buttonLabel}
              </Button>
            </Box>

            {/* Right Section: Image */}
            <Box className="image-section-learning-environments">
              <img
                src={contentMap[activeButton].image}
                alt={contentMap[activeButton].heading}
                className="responsive-image"
                style={{
                  maxWidth: "100%",
                  height: "50vh",
                }}
                loading="lazy"
              />
            </Box>
          </Box>
        ) : null}
      </Container>
    </div>
  );
}

export default Home;
