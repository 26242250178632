import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton, Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./LeadingLife.css";

const contentMap = {
  Foundation: {
    title: "Foundation",

    sections: [
      {
        image: "https://i.ibb.co/SDWYpYv1/LL-Foundation-1-EL-2.png",
        text: "Celebrating Life (Personal Excellence)",
        description:
          "Focus on personal transformation and building a foundation for excellence in life and work:\nUnderstand the source of happiness.\nBuild a mind-body connection.\nUse visualization and goal-setting to unlock potential.\nDevelop emotional resilience and harmony through personal transformation techniques.",
      },
    ],
  },
  "Emerging Leadership": {
    title: "Emerging Leadership",

    sections: [
      {
        image: "https://i.ibb.co/V0YQf0r1/LL-Emerging-leaders-1.png",
        description:
          "Prepare new leaders with foundational skills for leadership and management: \nDevelop emotional intelligence, team management, and decision-making capabilities.\nFoster structured growth through reflection, action learning, and implementation.",
        text: "First-Time Leaders",
      },
      {
        image: "https://i.ibb.co/SDWYpYv1/LL-Foundation-1-EL-2.png",
        description:
          "Reinforce personal growth to build resilience, mindfulness, and emotional intelligence in leadership roles.",
        text: "Celebrating Life (Personal Excellence)",
      },
    ],
  },
  "Advanced Leadership": {
    title: "Advanced Leadership",
    sections: [
      {
        image: "https://i.ibb.co/v6TdkCFj/LL-Advanced-1.png",
        text: "Leadership Learning from the Wild",
        description:
          "Nature-based experiential program to develop leadership instincts:\nFoster trust, collaboration, and resilience inspired by wildlife.\nObserve behaviors in the wild to gain insights into decision-making and innovation.",
      },
      {
        image: "https://i.ibb.co/GfCtjx0N/LL-Advanced-2.png",
        text: "Leadership Learning with Scuba",
        description:
          "Experiential learning program to enhance leadership capabilities:\nBuild teamwork, decision-making, adaptability, and stress management.\nStrengthen communication and resilience through immersive scuba diving experiences.",
      },
      {
        image: "https://i.ibb.co/SDWYpYv1/LL-Foundation-1-EL-2.png",
        text: "Celebrating Life (Personal Excellence)",
        description:
          "Advanced personal growth techniques tailored for experienced leaders:\nStrengthen emotional intelligence, work-life balance, and mindfulness to enhance leadership effectiveness.",
      },
      {
        image: "https://i.ibb.co/27RWQtSj/LL-Advanced-4.png",
        text: "Stakeholder Management (Synergize)",
        description:
          "Develop the ability to engage and manage stakeholders effectively:\nUnderstand stakeholder expectations.\nBuild trust and influence relationships. \nCreate actionable strategies for stakeholder alignment and organizational synergy.",
      },
      {
        image: "https://i.ibb.co/jvT8jStc/LL-Advanced-5.png",
        text: "Leadership Potential",
        description:
          "Identify and nurture high-potential leaders to build a future-ready leadership pipeline:\nStrategic role fitment, leadership readiness, and long-term planning.",
      },
    ],
  },
  "Strategic Growth": {
    title: "Strategic Growth",
    description:
      "Empowering individuals and organizations with innovative strategies for long-term success.",
    sections: [
      {
        image: "https://i.ibb.co/8qNvcpf/LL-Strategic-1.png",
        text: "Human Potential",
        description:
        "Empower organizations to unlock human potential and drive innovation:\nFoster a culture of growth, sustainability, and high-performing teams.\nAlign team goals with organizational success through a growth mindset.",
        
      },
      {
        image: "https://i.ibb.co/SDWYpYv1/LL-Foundation-1-EL-2.png",
        text: "Celebrating Life (Personal Excellence)",
        description:
        "Align personal and professional growth with organizational objectives:\nCreate a harmonious balance between individual goals and strategic priorities",
      },
    ],
  },
};

const LeadingLife = () => {
  const [activeButton, setActiveButton] = useState("Foundation");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="leading-life-page">
      {/* Hero Section */}
      <div className="leading-life-home-section">
        <div className="text-section-leadingLife-page">
          <h1 className="text-section-h1">
            Lead Life with
            <br />
            <span className="text-section-span">
              Personal and Professional Growth
            </span>
          </h1>
        </div>
      </div>

      <div className="leading-life-intro-section">
        <h1 className="text-section-leading-life">
          "Leading Life" empowers individuals and organizations to unlock
          potential through personal excellence, experiential learning, and
          strategic development. By fostering resilience, mindfulness, and
          leadership, it combines traditional techniques with innovative methods
          like
          <span style={{ color: "#0056c3" }}> scuba diving</span>,
          <span style={{ color: "#b1a201" }}> wilderness exploration</span>, and
          stakeholder synergy.
        </h1>
      </div>

      {/* Buttons Section */}
      {isMobile ? (
        <>
          {isExpanded ? (
            <Box
              className="leading-life-buttons-section expanded"
              alignItems="start"
            >
              <Box className="leading-life-button-container-mobile">
                {Object.keys(contentMap).map((button) => (
                  <Button
                    key={button}
                    variant={activeButton === button ? "contained" : "outlined"}
                    color={activeButton === button ? "primary" : "inherit"}
                    onClick={() => {
                      setActiveButton(button);
                      setIsExpanded(false);
                    }}
                    className={`leading-life-buttons-mobile ${
                      activeButton === button ? "active" : ""
                    }`}
                    sx={{ textTransform: "none" }}
                  >
                    {button}
                  </Button>
                ))}
              </Box>
              <IconButton
                color="primary"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          ) : (
            <Box className="leading-life-button-section-mobile">
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none", flexGrow: 1 }}
                className="leading-life-buttons-mobile"
              >
                {activeButton}
              </Button>
              <IconButton color="primary" onClick={() => setIsExpanded(true)}>
                <MenuIcon />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Container className="leading-life-button-section">
          <Box className="leading-life-button-container">
            {Object.keys(contentMap).map((button) => (
              <Button
                key={button}
                variant={activeButton === button ? "contained" : "outlined"}
                color={activeButton === button ? "primary" : "inherit"}
                onClick={() => setActiveButton(button)}
                className={`leading-life-button-card ${
                  activeButton === button ? "active" : ""
                }`}
                sx={{ textTransform: "none" }}
              >
                {button}
              </Button>
            ))}
          </Box>
        </Container>
      )}

      {/* Content Section */}
      <Container className="leading-life-content-section">
        {contentMap[activeButton].sections.map((section, index) => (
          <Box
            key={index}
            className="leading-life-content-box"
            sx={{
              p: 3,
              mb: 3,
              display: "flex",
              flexDirection: isMobile
                ? "column"
                : index % 2 === 0
                ? "row"
                : "row-reverse",
              alignItems: "center",
              backgroundColor: contentMap[activeButton].bgColor,
            }}
          >
            <Box sx={{ width: isMobile ? "100%" : "30%", textAlign: "center" }}>
              <img
                src={section.image}
                alt={section.text}
                style={{ maxWidth: "100%", borderRadius: "10px" }}
              />
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "70%", padding: "20px" }}>
              <Typography
                variant="h3"
                gutterBottom
                color="#00AAFC"
                sx={{
                  fontFamily: "Nunito",
                  fontWeight: "700",
                  fontSize: "25px",
                  textAlign: isMobile
                    ? "center"
                    : index % 2 === 0
                    ? "left"
                    : "right",
                }}
              >
                {section.text}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Nunito",
                  fontSize: "18px",
                  textAlign: isMobile
                    ? "center"
                    : index % 2 === 0
                    ? "left"
                    : "right",
                }}
              >
                {section?.description.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </Typography>
            </Box>
          </Box>
        ))}
      </Container>
    </div>
  );
};

export default LeadingLife;
